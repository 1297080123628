import React, { useState, useEffect } from 'react';
import Radium from 'radium';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Fade } from 'react-reveal';
import get from 'lodash/get';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import { isAndBelow } from '../util';

const AnimatedLink = styled.a(({ isDisabled, color }) => ({
  position: 'relative',
  color: isDisabled ? 'rgba(156, 163, 175, 1)' : color || 'inherit',
  '&:before': {
    content: '""',
    position: 'absolute',
    width: 0,
    height: '2px',
    bottom: '-6px',
    left: 0,
    backgroundColor: color || '#fff',
    visibility: 'hidden',
    transition: 'all 0.3s ease-in-out'
  },
  '&:hover:before': {
    visibility: 'visible',
    width: '100%'
  }
}));

const FullScreenImage = styled.div(({ isVisible, image }) => ({
  opacity: isVisible ? 1 : 0,
  background: `url('${image}') no-repeat top center/cover`,
  position: 'absolute',
  minWidth: '100%',
  minHeight: '100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  transition: 'opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
}));

const FullScreenImageOverlay = styled.div(({ isVisible }) => ({
  opacity: isVisible ? 1 : 0,
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  transition: 'opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
}));

const ArtistsIndex = props => {
  const zeoMusicArtists = get(props, 'data.allContentfulZeoMusicArtist.nodes');
  const artistsWorkedWith = get(props, 'data.allContentfulArtistWorkedWith.nodes');

  const breakpoints = useBreakpoint();

  const [zmArtistActiveIndex, setZmArtistActiveIndex] = useState(null);
  const [zmArtistActiveAutoIndex, setZmArtistActiveAutoIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (zmArtistActiveAutoIndex < zeoMusicArtists.length - 1) {
        setZmArtistActiveAutoIndex(prev => prev + 1);
      } else {
        setZmArtistActiveAutoIndex(0);
      }
    }, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [zmArtistActiveAutoIndex]);

  const onZmArtistEnter = index => {
    if (!isAndBelow('md', breakpoints)) {
      setZmArtistActiveIndex(index);
    }
  };

  const onZmArtistLeave = () => {
    if (!isAndBelow('md', breakpoints)) {
      setZmArtistActiveIndex(null);
    }
  };

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="Artists" />
      <div
        id="zeomusic"
        className="bg-gray-900 relative overflow-hidden flex flex-wrap items-center justify-center content-center w-full min-h-screen h-auto p-6">
        <div>
          {zeoMusicArtists.map((artist, index) => (
            <FullScreenImage
              key={index}
              image={artist.image.file.url}
              isVisible={
                (isAndBelow('md', breakpoints) && zmArtistActiveAutoIndex === index) ||
                (!isAndBelow('md', breakpoints) && zmArtistActiveIndex === index)
              }
            />
          ))}
        </div>
        <FullScreenImageOverlay
          isVisible={
            (isAndBelow('md', breakpoints) && zmArtistActiveAutoIndex) ||
            (!isAndBelow('md', breakpoints) && zmArtistActiveIndex)
          }
        />
        <div
          className="relative flex flex-wrap items-center justify-around content-center w-full h-auto py-12"
          style={{ maxWidth: 1280 }}>
          {zeoMusicArtists.map((artist, index) => (
            <Fade duration={2000} delay={200 * index}>
              <p
                key={index}
                className="text-white h-auto m-0 text-xl sm:text-2xl md:text-3xl text-center font-bold uppercase tracking-widest w-full md:w-auto p-4 sm:p-5 md:py-8 md:px-6">
                <AnimatedLink
                  href={`/artists/${artist.slug}`}
                  isDisabled={isAndBelow('md', breakpoints) && zmArtistActiveAutoIndex !== index}
                  onMouseEnter={() => onZmArtistEnter(index)}
                  onMouseLeave={onZmArtistLeave}>
                  {artist.name}
                </AnimatedLink>
              </p>
            </Fade>
          ))}
        </div>
      </div>
      <div id="artistsworkedwith" className="flex flex-col items-center w-full p-6">
        <p className="mt-12 text-center text-2xl sm:text-3xl max-w-sm sm:max-w-lg leading-8 font-extrabold text-blue tracking-wider">
          Artists We've Worked With
        </p>
        <div
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-2 sm:gap-3 md:gap-4 lg:gap-6 w-full py-12"
          style={{ maxWidth: 1280 }}>
          {artistsWorkedWith.map((artist, index) => (
            <Fade key={index} duration={2000} delay={200 * index}>
              <div className="flex flex-col items-center">
                <div
                  style={{
                    background: `url(${artist.image.file.url}) no-repeat center center/cover`,
                    cursor: 'pointer'
                  }}
                />
                <img
                  className="rounded-full"
                  src={artist.image.file.url}
                  alt={artist.image.title}
                  style={{ width: '65%' }}
                />
                <p className="text-blue h-auto m-0 text-xl text-center font-bold uppercase tracking-widest w-full md:w-auto p-5">
                  <AnimatedLink href={artist.link} target="_blank">
                    {artist.name}
                  </AnimatedLink>
                </p>
              </div>
            </Fade>
          ))}
        </div>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default Radium(ArtistsIndex);

export const pageQuery = graphql`
  query ArtistsIndexQuery {
    allContentfulZeoMusicArtist(sort: { fields: order }) {
      nodes {
        name
        image {
          title
          file {
            url
          }
        }
        slug
      }
    }
    allContentfulArtistWorkedWith(sort: { fields: order }) {
      nodes {
        name
        link
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
`;
